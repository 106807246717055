<i18n src="@/locales/pages/home.json"></i18n>

<template>
  <article>
    <HomeSection />
    <AboutSection />
    <PortfolioSection />
    <ContactSection />
  </article>
</template>

<script>
import HomeSection from './sections/HomeSection';
import AboutSection from './sections/AboutSection';
import PortfolioSection from './sections/PortfolioSection';
import ContactSection from './sections/ContactSection';

import Rellax from 'rellax';
import debounce from 'lodash/debounce';

export default {
  name: 'Home',
  components: {
    HomeSection,
    AboutSection,
    PortfolioSection,
    ContactSection,
  },
  metaInfo() {
    return {
      title: 'Start ◂ Dennis Adamczyk',
      meta: [
        {
          name: 'description',
          content:
            'Dennis Adamczyk • Web Developer & UI/UX Designer – Meine Website beinhaltet mein Portfolio mit Programmier-Projekten und weitere Informationen über mich.',
        },
        {
          name: 'keywords',
          content:
            'dennis adamczyk, programmierung, development, programmierer, portfolio, duisburg',
        },
        { name: 'robots', content: 'index,follow' },
      ],
    };
  },
  data() {
    return {
      activeSection: 'home',
      sections: {},
      ignoreRouteChange: false,
      meta: {
        home: [
          {
            name: 'description',
            content:
              'Dennis Adamczyk • Web Developer & UI/UX Designer – Meine Website beinhaltet mein Portfolio mit Programmier-Projekten und weitere Informationen über mich.',
          },
          {
            name: 'keywords',
            content:
              'dennis adamczyk, programmierung, development, programmierer, portfolio, duisburg',
          },
        ],
        about: [
          {
            name: 'description',
            content:
              'Über Mich – Dennis Adamczyk • Web Developer & UI/UX Designer – Mehr über meine Leistungen (Programmierung & Design) und mich persönlich.',
          },
          {
            name: 'keywords',
            content:
              'dennis adamczyk, über mich, über, dienstleistungen, persönlich, programmierung, development, programme, tools, duisburg',
          },
        ],
        portfolio: [
          {
            name: 'description',
            content:
              'Portfolio – Dennis Adamczyk • Web Developer & UI/UX Designer – Einen Einblick in meine Arbeit gefällig? Meine letzten Programmier-, Web- und Software-Projekte.',
          },
          {
            name: 'keywords',
            content:
              'dennis adamczyk, portfolio, projekte, arbeit, arbeitsprobe, programmierer, development, duisburg',
          },
        ],
        contact: [
          {
            name: 'description',
            content:
              'Kontakt – Dennis Adamczyk • Web Developer & UI/UX Designer – Du willst mit mir ein Projekt starten oder einfach mal Hallo sagen? Ich habe ein offenes Ohr.',
          },
          {
            name: 'keywords',
            content:
              'dennis adamczyk, kontakt, email, social media, programmierer, development, duisburg',
          },
        ],
      },
    };
  },
  watch: {
    activeSection(value) {
      if (this.$route.path == '/' + value) return;
      this.ignoreRouteChange = true;
      this.$router.replace({
        path: value,
        params: this.$route.params,
        query: this.$route.query,
      });
    },
    $route(value) {
      let newTitle =
        this.$t('header.navigation.' + (value.path.substr(1) || 'home')) +
        ' ◂ Dennis Adamczyk';
      document.title = newTitle;
      this.$metaInfo.title = newTitle;
      this.$metaInfo.meta = this.meta[value.path.substr(1) || 'home'];

      if (this.ignoreRouteChange) return (this.ignoreRouteChange = false);
      this.scrollTo(this.sections[value.path.substr(1)]);
      this.ignoreRouteChange = false;
    },
  },
  methods: {
    handleScroll() {
      let scroll = window.scrollY;
      if (scroll < 0) return;

      for (const sectionName in this.sections) {
        if (!Object.prototype.hasOwnProperty.call(this.sections, sectionName))
          continue;
        const section = this.sections[sectionName];
        if (
          scroll >
            section.offsetTop -
              0.5 * window.innerHeight +
              (sectionName == 'contact'
                ? window.outerWidth < 600
                  ? 96
                  : 144
                : 0) &&
          scroll <
            section.offsetTop +
              section.offsetHeight -
              0.5 * window.innerHeight +
              (sectionName == 'portfolio'
                ? window.outerWidth < 600
                  ? 96
                  : 144
                : 0)
        ) {
          this.activeSection = sectionName;
          break;
        }
      }
    },
    scrollTo(target) {
      let targetPosition =
        target.offsetTop -
        (window.outerWidth < 600 ? 56 : 64) +
        (target.getAttribute('id') == 'contact'
          ? window.outerWidth < 600
            ? 96
            : 144
          : 0);
      if ('scrollBehavior' in document.documentElement.style) {
        window.scroll({
          top: targetPosition,
          behavior: 'smooth',
        });
      } else {
        const duration = 500;
        let startPosition = window.scrollY;
        let distance = targetPosition - startPosition;
        let startTime = null;

        let animation = (currentTime) => {
          if (startTime == null) startTime = currentTime;
          let timeElapsed = currentTime - startTime;
          let run = ease(timeElapsed, startPosition, distance, duration);
          window.scrollTo(0, run);
          if (timeElapsed < duration) requestAnimationFrame(animation);
        };

        let ease = (t, b, c, d) => {
          t /= d;
          return -c * t * (t - 2) + b;
        };

        requestAnimationFrame(animation);
      }
    },
  },
  mounted() {
    this.sections = {
      '': document.getElementById('home'),
      about: document.getElementById('about'),
      portfolio: document.getElementById('portfolio'),
      contact: document.getElementById('contact'),
    };
    this.handleDebouncedScroll = debounce(this.handleScroll, 50);
    window.addEventListener('scroll', this.handleDebouncedScroll);
    window.scrollTo({
      top:
        this.sections[this.$route.path.substr(1)].offsetTop +
        (this.$route.path.substr(1) == 'contact'
          ? window.outerWidth < 600
            ? 96
            : 144
          : 0),
    });
    let newTitle =
      this.$t('header.navigation.' + (this.$route.path.substr(1) || 'home')) +
      ' ◂ Dennis Adamczyk';
    document.title = newTitle;
    this.$metaInfo.title = newTitle;
    this.$metaInfo.meta = this.meta[this.$route.path.substr(1) || 'home'];
    this.$ga.page({
      page: '/',
      title: newTitle,
      location: window.location.href,
    });

    new Rellax('.rellax', {
      breakpoints: [600, 768, 1201],
    });
  },
  destroyed() {
    window.removeEventListener('scroll', this.handleDebouncedScroll);
  },
};
</script>

<style lang="scss" scoped></style>
