var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{attrs:{"id":"home"}},[_c('div',{staticClass:"content"},[_c('div',{staticClass:"inner"},[_c('div',{staticClass:"text"},[_c('div',{staticClass:"title"},[_c('p',{staticClass:"before"},[_vm._v(_vm._s(_vm.$t('home.title.before')))]),_c('h1',{domProps:{"innerHTML":_vm._s(_vm.$t('home.title.headline'))}}),_c('h2',{staticClass:"subtitle"},[_vm._v(_vm._s(_vm.$t('home.title.subtitle')))])]),_c('div',{staticClass:"buttons"},[_c('BaseButton',{staticClass:"portfolio",attrs:{"color":"primary","to":{
              path: '/portfolio',
              params: this.$route.params,
              query: this.$route.query,
            },"replace":""}},[_vm._v(_vm._s(_vm.$t('home.buttons.portfolio'))+" ")]),_c('BaseButton',{staticClass:"contact",attrs:{"outline":"white","to":{
              path: '/contact',
              params: this.$route.params,
              query: this.$route.query,
            },"replace":""}},[_vm._v(_vm._s(_vm.$t('home.buttons.contact'))+" ")])],1)]),_vm._m(0)]),_c('router-link',{staticClass:"scroll",attrs:{"tag":"div","to":{
        path: '/about',
        params: this.$route.params,
        query: this.$route.query,
      },"replace":""}},[_c('p',[_vm._v(_vm._s(_vm.$t('home.scrollDown')))]),_c('div',{staticClass:"arrow"})])],1)])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"portrait"},[_c('picture',[_c('source',{attrs:{"srcset":require("@/assets/home/portrait.webp"),"type":"image/webp"}}),_c('source',{attrs:{"srcset":require("@/assets/home/portrait.png"),"type":"image/png"}}),_c('img',{staticClass:"rellax",attrs:{"src":require("@/assets/home/portrait.png"),"alt":"Portrait","data-rellax-speed":"-1"}})])])}]

export { render, staticRenderFns }