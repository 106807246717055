<i18n src="@/locales/pages/home.json"></i18n>

<template>
  <section id="about">
    <div class="content">
      <p
        class="background-text rellax"
        data-rellax-speed="-1"
        data-rellax-percentage="0.5"
      >
        {{ $t('about.title') }}
      </p>
      <div class="foreground">
        <h2 class="title">{{ $t('about.title') }}</h2>
        <div class="heading">
          <p class="headline">{{ $t('about.heading.headline') }}</p>
        </div>
        <div class="inner">
          <div class="text">
            <i18n path="about.paragraphs.0" tag="p">
              <span class="duration">{{ getDuration }}</span>
              <span class="age">{{ getAge }}</span>
            </i18n>
            <p>{{ $t('about.paragraphs.1') }}</p>
          </div>
          <div class="boxes">
            <div class="box" v-for="index in 3" :key="index">
              <div class="header">
                <div class="title">
                  <p class="number">0{{ index }}</p>
                  <p class="title">{{ $t(`about.boxes.${index}.title`) }}</p>
                </div>
                <div class="icon">
                  <i class="icon-bulb" v-if="index == 1"></i>
                  <i class="icon-brush" v-if="index == 2"></i>
                  <i class="icon-code" v-if="index == 3"></i>
                </div>
              </div>
              <p class="description">{{ $t(`about.boxes.${index}.text`) }}</p>
            </div>
            <div
              class="dot-pattern rellax"
              data-rellax-speed="-.2"
              data-rellax-percentage=".2"
            ></div>
          </div>
        </div>
        <div class="tools">
          <h3>{{ $t('about.tools.heading') }}</h3>

          <div class="slider">
            <button class="swiper-button-prev">
              <i class="icon-left" />
            </button>

            <div class="swiper-container">
              <div class="swiper-wrapper">
                <div
                  class="swiper-slide"
                  v-for="tool in tools"
                  :key="tool.src"
                  :data-tooltip="tool.title"
                >
                  <img
                    :src="require(`@/assets/home/tools/${tool.src}`)"
                    :alt="tool.title"
                  />
                </div>
              </div>
            </div>

            <button class="swiper-button-next">
              <i class="icon-right" />
            </button>
          </div>
        </div>
      </div>
      <picture
        class="portrait rellax"
        data-rellax-speed="-1"
        data-rellax-percentage="-.3"
      >
        <source srcset="@/assets/home/portrait2.webp" type="image/webp" />
        <source srcset="@/assets/home/portrait2.png" type="image/png" />
        <img src="@/assets/home/portrait2.png" alt="" />
      </picture>
    </div>
  </section>
</template>

<script>
import Swiper from 'swiper';

export default {
  name: 'AboutSection',
  data() {
    return {
      tools: [
        {
          src: 'figma.svg',
          title: 'Figma',
        },
        {
          src: 'adobeXd.svg',
          title: 'Adobe XD',
        },

        {
          src: 'html5.svg',
          title: 'HTML 5',
        },
        {
          src: 'css3.svg',
          title: 'CSS 3',
        },
        {
          src: 'sass.svg',
          title: 'SASS/SCSS',
        },
        {
          src: 'javascript.svg',
          title: 'JavaScript',
        },
        {
          src: 'typescript.svg',
          title: 'TypeScript',
        },
        {
          src: 'nodeJs.svg',
          title: 'Node.js',
        },
        {
          src: 'react.svg',
          title: 'React',
        },
        {
          src: 'nextjs.svg',
          title: 'Next.js',
        },
        {
          src: 'vue.svg',
          title: 'Vue',
        },
        {
          src: 'angular.svg',
          title: 'Angular',
        },
        {
          src: 'adobePhotoshop.svg',
          title: 'Adobe Photoshop',
        },
        {
          src: 'adobeIllustrator.svg',
          title: 'Adobe Illustrator',
        },
        {
          src: 'adobeInDesign.svg',
          title: 'Adobe InDesign',
        },
        {
          src: 'adobePremiere.svg',
          title: 'Adobe Premiere Pro',
        },
        {
          src: 'adobeAudition.svg',
          title: 'Adobe Audition',
        },
        {
          src: 'firebase.svg',
          title: 'Google Firebase',
        },
        {
          src: 'java.svg',
          title: 'Java',
        },
        {
          src: 'php.svg',
          title: 'PHP',
        },
        {
          src: 'wordpress.svg',
          title: 'WordPress',
        },
      ],
    };
  },
  mounted() {
    new Swiper('#about .swiper-container', {
      spaceBetween: 24,
      slidesPerView: 9,
      grabCursor: true,
      loop: true,
      autoHeight: true,
      navigation: {
        nextEl: '#about .swiper-button-next',
        prevEl: '#about .swiper-button-prev',
      },
      breakpoints: {
        0: {
          slidesPerView: 'auto',
        },
        180: {
          slidesPerView: 1,
        },
        260: {
          slidesPerView: 2,
        },
        350: {
          slidesPerView: 3,
        },
        420: {
          slidesPerView: 4,
        },
        510: {
          slidesPerView: 5,
        },
        600: {
          slidesPerView: 5,
        },
        700: {
          slidesPerView: 6,
        },
        800: {
          slidesPerView: 7,
        },
        900: {
          slidesPerView: 8,
        },
        1000: {
          slidesPerView: 9,
        },
      },
    });
  },
  computed: {
    getDuration() {
      return new Date(new Date() - new Date('2015-03-01')).getFullYear() - 1970;
    },
    getAge() {
      let ageDifMs = Date.now() - new Date('2003-06-11');
      let ageDate = new Date(ageDifMs);
      return Math.abs(ageDate.getUTCFullYear() - 1970);
    },
  },
};
</script>

<style lang="scss" scoped>
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@900&display=swap');
@import '~swiper/swiper';

#about {
  width: 100%;
  padding: 96px 0;
  background-color: $background-light;
  overflow: hidden;
  position: relative;

  @media all and ($desktop) {
    padding: 144px 0;
  }

  .content {
    max-width: $max-view-width;
    height: auto;
    padding: 0 24px;
    margin: 0 auto;
    position: relative;

    .background-text {
      position: absolute;
      top: -144px;
      left: 40%;
      font-size: 18.75rem;
      font-weight: 700;
      line-height: 25.5625rem;
      color: rgba($foreground, 0.02);
      text-transform: uppercase;
      white-space: nowrap;
      user-select: none;

      @media all and ($mobile) {
        top: -96px;
        left: -14px;
        font-size: 11.25rem;
        line-height: 15.375rem;
      }
    }

    .foreground {
      position: relative;
      z-index: 1;

      h2.title {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        text-transform: uppercase;
        font-size: 1rem;
        font-weight: 300;
        letter-spacing: 0.15em;
        color: $foreground;

        &::before {
          content: '';
          display: inline-block;
          width: 12px;
          height: 2px;
          background-color: $primary;
          margin: 0 8px 0 0;
        }
      }

      .heading {
        margin: 40px 0 0 0;

        @media all and ($mobile) {
          margin: 32px 0 0 0;
        }

        .before {
          font-size: 1.5rem;
          font-weight: 700;
          text-align: left;
          color: $foreground;
          margin: 0 0 16px 0;

          @media all and ($mobile) {
            font-size: 1.25rem;
          }
        }

        .headline {
          font-size: 2rem;
          font-weight: 700;
          text-align: left;
          color: $foreground;

          @media all and ($mobile) {
            font-size: 1.375rem;
          }

          span {
            color: $primary;

            &::before {
              content: '\A';
              white-space: pre;
            }
          }
        }
      }

      .inner {
        display: flex;
        justify-content: space-between;
        align-items: center;

        @media all and ($mobile) {
          display: block;
        }

        .text {
          width: 40%;
          margin: 40px 0;

          @media all and ($mobile) {
            width: 100%;
            margin: 48px 0 96px 0;
          }

          p {
            line-height: 1.375rem;
            margin: 0 0 24px 0;

            @media all and ($mobile) {
              margin: 0 0 16px 0;
            }

            &:last-child {
              margin: 0;
            }
          }
        }

        .boxes {
          max-width: 60%;
          display: grid;
          grid-template:
            [row1-start] 'second first' 1fr [row1-end]
            [row2-start] 'second third' 1fr [row2-end]
            / auto auto;
          align-items: center;
          grid-gap: 32px 24px;

          @media all and ($mobile) {
            max-width: 100%;
            width: 100%;
            grid-template:
              [row1-start] 'first' auto [row1-end]
              [row2-start] 'second' auto [row2-end]
              [row3-start] 'third' auto [row3-end]
              / 100%;
            grid-gap: 24px 0;
            position: relative;
          }

          .dot-pattern {
            display: block;
            position: absolute;
            top: 0;
            right: 0;

            &::before {
              content: '';
              display: block;
              width: 134px;
              height: 80px;
              transform: translate(32%, -73%);
              z-index: 0;

              @include circle-pattern(0.2);
            }

            @media all and ($desktop) {
              display: none;
            }
          }

          .box {
            max-width: 256px;
            height: auto;
            box-sizing: border-box;
            background-color: $background;
            border-radius: 20px;
            box-shadow: map-get($elevation, 1);
            padding: 24px 16px 16px 16px;
            z-index: 1;

            @media all and ($mobile) {
              max-width: none;
              width: 100%;
            }

            &:nth-child(1) {
              grid-area: first;
            }

            &:nth-child(2) {
              grid-area: second;
            }

            &:nth-child(3) {
              grid-area: third;
            }

            .header {
              display: flex;
              justify-content: space-between;
              align-items: flex-start;
              margin: 0 0 16px 0;

              .title {
                .number {
                  text-align: left;
                  font-family: 'Montserrat', 'Nunito Sans', sans-serif;
                  font-size: 1rem;
                  font-weight: 900;
                  line-height: 19px;
                  color: $primary;
                  margin: 0;
                }
                .title {
                  text-align: left;
                  font-size: 1.25rem;
                  font-weight: 700;
                  line-height: 27px;
                  color: $foreground;
                }
              }

              .icon {
                display: flex;
                justify-content: center;
                align-items: center;
                width: 64px;
                height: 64px;
                background-color: rgba($primary, 0.2);
                border-radius: 50%;

                i {
                  font-size: 1.5rem;
                  color: $primary;
                }
              }
            }

            .description {
              line-height: 1.2rem;
            }
          }
        }
      }

      .tools {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        margin: 96px 0 0 0;

        @media all and ($mobile) {
          margin-bottom: 32px;
        }

        h3 {
          font-size: 1.5rem;
          font-weight: 700;
          text-align: center;
          color: $foreground;
          margin: 0 0 32px 0;
        }

        .slider {
          display: flex;
          justify-content: space-between;
          align-items: center;
          width: 100%;

          .swiper-container {
            width: calc(100% - 80px);
            padding-bottom: 64px;
            margin-bottom: -64px;

            .swiper-wrapper {
              .swiper-slide {
                display: flex;
                justify-content: center;
                align-items: center;
                width: 64px;
                height: 64px;
                background-color: $background;
                border-radius: 16px;
                overflow: visible;
                user-select: none;
                transition: box-shadow 150ms ease;

                &:hover {
                  box-shadow: map-get($elevation, 2);

                  img {
                    opacity: 1;
                  }
                }

                img {
                  max-width: 48px;
                  max-height: 48px;
                  opacity: 0.25;
                  transition: opacity 150ms ease;
                }
              }
            }
          }
          button {
            border: none;
            background-color: transparent;
            outline: none;
            padding: 11px;
            margin: 0 8px;
            cursor: pointer;
            box-sizing: border-box;
            border-radius: 50%;
            z-index: 1;
            transition: background 100ms ease-in-out;

            &:active {
              background-color: rgba($foreground, 0.1);
            }

            &.swiper-button-prev {
              left: -40px;
              margin: 0 8px 0 -8px;
            }

            &.swiper-button-next {
              right: -40px;
              margin: 0 -8px 0 8px;
            }

            i {
              width: 18px;
              height: 18px;
              font-size: 18px;
              color: $foreground;
            }
          }
        }
      }
    }

    .portrait {
      display: flex;
      justify-content: center;
      align-items: center;
      position: absolute;
      left: -25%;
      bottom: -144px;
      width: 60%;
      z-index: 0;
      user-select: none;
      opacity: 0.1;

      @media all and ($mobile) {
        left: auto;
        right: 0;
        bottom: -128px;
        width: 145%;
      }

      img {
        width: 100%;
        height: auto;
      }
    }
  }
}
</style>
