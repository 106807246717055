<i18n src="@/locales/pages/home.json"></i18n>

<template>
  <section id="contact">
    <div class="content">
      <p
        class="background-text rellax"
        data-rellax-speed="-1"
        data-rellax-percentage="0.5"
      >
        {{ $t('contact.title') }}
      </p>
      <div class="foreground">
        <h2 class="title">{{ $t('contact.title') }}</h2>
        <p class="headline">{{ $t('contact.headline') }}</p>
        <div class="inner">
          <div class="text">
            <p class="subtitle">{{ $t('contact.subtitle') }}</p>
            <p class="details">{{ $t('contact.details') }}</p>
          </div>
          <form
            id="contact-form"
            :class="status"
            @submit.prevent="submit"
            @input="checkValidation"
          >
            <BaseInput
              name="name"
              :label="$t('contact.form.name.label')"
              :validator="validators.name"
              autocomplete="name"
              ref="name"
              v-model="data.name"
            />
            <BaseInput
              name="company"
              :label="$t('contact.form.company.label')"
              autocomplete="organization"
              ref="company"
              v-model="data.company"
            />
            <BaseInput
              name="email"
              :label="$t('contact.form.email.label')"
              type="email"
              :validator="validators.email"
              autocomplete="email"
              ref="email"
              v-model="data.email"
            />
            <BaseInput
              name="phone"
              :label="$t('contact.form.phone.label')"
              type="tel"
              :validator="validators.phone"
              autocomplete="tel"
              ref="phone"
              v-model="data.phone"
            />
            <BaseInput
              name="subject"
              :label="$t('contact.form.subject.label')"
              autocomplete="off"
              ref="subject"
              v-model="data.subject"
            />
            <BaseInput
              name="message"
              :label="$t('contact.form.message.label')"
              type="textarea"
              autogrow
              box
              :validator="validators.message"
              autocomplete="off"
              ref="message"
              v-model="data.message"
            />
            <BaseButton
              color="secondary"
              submit
              ref="submit"
              :class="{ disabled: isInvalid }"
              >{{ $t('contact.form.submit') }}
            </BaseButton>
            <div class="feedback" v-if="status !== 'input'">
              <div class="loader" v-if="status == 'loading'">
                <svg class="circular" viewBox="25 25 50 50">
                  <circle
                    cx="50"
                    cy="50"
                    r="20"
                    fill="none"
                    stroke-width="4"
                    stroke-miterlimit="10"
                  ></circle>
                </svg>
              </div>
              <div class="success" v-if="status == 'success'">
                <div class="symbol">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="59.314"
                    height="42.657"
                    viewBox="0 0 59.314 42.657"
                  >
                    <path
                      d="M54,9,21,42,6,27"
                      transform="translate(-0.343 -3.343)"
                      fill="none"
                      stroke="#fff"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="8"
                    />
                  </svg>
                </div>
                <p class="title">
                  {{ $t('contact.form.feedback.success.title') }}
                </p>
                <p class="details">
                  {{ $t('contact.form.feedback.success.details') }}
                </p>
              </div>
              <div class="error" v-if="status == 'error' && error == 'unknown'">
                <div class="symbol">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="10"
                    height="52"
                    viewBox="0 0 10 47"
                  >
                    <g transform="translate(-943 -3100.5)">
                      <line
                        y2="26"
                        fill="none"
                        stroke="#fff"
                        stroke-linecap="round"
                        stroke-width="10"
                        transform="translate(948 3103)"
                      />
                      <line
                        transform="translate(948 3145)"
                        fill="none"
                        stroke="#fff"
                        stroke-linecap="round"
                        stroke-width="10"
                      />
                    </g>
                  </svg>
                </div>
                <p class="title">
                  {{ $t('contact.form.feedback.error.unknown.title') }}
                </p>
                <p class="details">
                  {{ $t('contact.form.feedback.error.unknown.details') }}
                </p>
                <BaseButton
                  color="white"
                  :to="
                    `mailto:ich@dennis-adamczyk.de?subject=${
                      data.subject
                    }&body=${data.message.replace(/\n/g, '%0D%0A')}`
                  "
                  anchor
                  >{{ $t('contact.form.feedback.error.unknown.button') }}
                </BaseButton>
              </div>
              <div class="error" v-if="status == 'error' && error == 'robot'">
                <div class="symbol">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="48"
                    height="38.4"
                    viewBox="0 0 48 38.4"
                  >
                    <path
                      d="M0,19.2v9.6a2.4,2.4,0,0,0,2.4,2.4H4.8V16.8H2.4A2.4,2.4,0,0,0,0,19.2Zm34.8-12H26.4V2.4a2.4,2.4,0,0,0-4.8,0V7.2H13.2a6,6,0,0,0-6,6V33.6A4.8,4.8,0,0,0,12,38.4H36a4.8,4.8,0,0,0,4.8-4.8V13.2A6,6,0,0,0,34.8,7.2Zm-15.6,24H14.4V28.8h4.8Zm-2.4-9a3,3,0,1,1,3-3A3,3,0,0,1,16.8,22.2Zm9.6,9H21.6V28.8h4.8Zm7.2,0H28.8V28.8h4.8Zm-2.4-9a3,3,0,1,1,3-3A3,3,0,0,1,31.2,22.2Zm14.4-5.4H43.2V31.2h2.4A2.4,2.4,0,0,0,48,28.8V19.2A2.4,2.4,0,0,0,45.6,16.8Z"
                      fill="#fff"
                    />
                  </svg>
                </div>
                <p class="title">
                  {{ $t('contact.form.feedback.error.robot.title') }}
                </p>
                <i18n
                  tag="p"
                  path="contact.form.feedback.error.robot.details"
                  class="details"
                >
                  <template v-slot:imprint>
                    <router-link to="/imprint"
                      >{{ $t('contact.form.feedback.error.robot.imprint') }}
                    </router-link>
                  </template>
                  <template v-slot:privacyPolicy>
                    <router-link to="/privacy"
                      >{{
                        $t('contact.form.feedback.error.robot.privacyPolicy')
                      }}
                    </router-link>
                  </template>
                </i18n>
              </div>
            </div>
          </form>
          <div
            class="dot-pattern rellax"
            data-rellax-speed="-.3"
            data-rellax-percentage=".2"
          ></div>
        </div>

        <div class="socialMedia">
          <p class="headline">{{ $t('contact.socialMedia.headline') }}</p>
          <div class="boxes">
            <a
              href="https://www.instagram.com/dennis.adamczyk/"
              target="_blank"
              class="box"
            >
              <i class="icon-social-instagram"></i>
              <p class="title">{{ $t('contact.socialMedia.instagram') }}</p>
              <p class="name">@dennis.adamczyk</p>
            </a>
            <a
              href="https://github.com/dennis-adamczyk/"
              target="_blank"
              class="box"
            >
              <i class="icon-social-github"></i>
              <p class="title">{{ $t('contact.socialMedia.github') }}</p>
              <p class="name">@dennis-adamczyk</p>
            </a>
            <a href="https://twitter.com/DevDennis" target="_blank" class="box">
              <i class="icon-social-twitter"></i>
              <p class="title">{{ $t('contact.socialMedia.twitter') }}</p>
              <p class="name">@DevDennis</p>
            </a>
            <a
              href="https://www.linkedin.com/in/dennis-adamczyk-368b82238/"
              target="_blank"
              class="box"
            >
              <i class="icon-social-linkedin"></i>
              <p class="title">{{ $t('contact.socialMedia.linkedIn') }}</p>
              <p class="name">Dennis Adamczyk</p>
            </a>
            <a
              href="https://www.xing.com/profile/Dennis_Adamczyk5/cv"
              target="_blank"
              class="box"
            >
              <i class="icon-social-xing"></i>
              <p class="title">{{ $t('contact.socialMedia.xing') }}</p>
              <p class="name">Dennis_Adamczyk5</p>
            </a>
            <a href="mailto:ich@dennis-adamczyk.de" target="_blank" class="box">
              <i class="icon-social-email"></i>
              <p class="title">{{ $t('contact.socialMedia.email') }}</p>
              <p class="name">ich@dennis-adamczyk.de</p>
            </a>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import axios from 'axios';
import { load } from 'recaptcha-v3';

export default {
  name: 'ContactSection',
  data() {
    return {
      data: {
        name: '',
        company: '',
        email: '',
        phone: '',
        subject: '',
        message: '',
      },
      isInvalid: true,
      status: 'input',
      error: false,
    };
  },
  methods: {
    checkValidation() {
      let invalid = false;
      for (const inputName in this.$refs) {
        if (!Object.prototype.hasOwnProperty.call(this.$refs, inputName))
          continue;
        const input = this.$refs[inputName];
        if (!input.invalid) continue;
        invalid = true;
        break;
      }
      return (this.isInvalid = invalid);
    },
    async submit() {
      if (this.isInvalid) {
        for (const inputName in this.$refs) {
          if (!Object.prototype.hasOwnProperty.call(this.$refs, inputName))
            continue;
          const input = this.$refs[inputName];
          input.dirty = true;
        }
        return;
      }

      this.status = 'loading';
      for (const inputName in this.$refs) {
        if (!Object.prototype.hasOwnProperty.call(this.$refs, inputName))
          continue;
        const input = this.$refs[inputName]?.$refs?.input;
        if (!input) continue;
        input.disabled = true;
      }

      let data = Object.assign({}, this.data);
      const recaptcha = await load('6LdyrugUAAAAAJT3SV41IcWZe799qdiFZJLLIozy', {
        autoHideBadge: true,
      });

      try {
        // Execute reCAPTCHA with action "contact".
        const token = await recaptcha.execute('contact');
        data.token = token;
      } catch (error) {
        console.error(error);
        this.status = 'error';
        this.error = 'unknown';
      }

      console.log(data);
      try {
        let res = await axios.post(
          'https://api.dennis-adamczyk.de/sendContactRequest',
          data
        );
        // console.log(res);

        this.status = res.data.success ? 'success' : 'error';
        if (!res.data.success) {
          if (res.data.robot) this.error = 'robot';
          else this.error = 'unknown';
          console.error(res);
        } else this.error = false;
      } catch (error) {
        this.status = 'error';
        this.error = 'unknown';
        console.error(error);
      }
    },
  },
  created() {
    let validators = {
      name: new Map(),
      email: new Map(),
      phone: new Map(),
      message: new Map(),
    };

    /* ===== NAME ===== */
    validators.name.set(/.*\S.*/, () =>
      this.$t('contact.form.name.errors.required')
    );
    validators.name.set(
      /^[a-zA-ZàáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ð ,.'-]+$/u,
      () => this.$t('contact.form.name.errors.invalidCharacters')
    );
    validators.name.set(/^\S{2,}\s+\S{2,}/, () =>
      this.$t('contact.form.name.errors.firstAndLastName')
    );

    /* ===== EMAIL ===== */
    validators.email.set(/.*\S.*/, () =>
      this.$t('contact.form.email.errors.required')
    );
    validators.email.set(
      /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/,
      () => this.$t('contact.form.email.errors.invalid')
    );

    /* ===== PHONE ===== */
    validators.phone.set(
      /(^\s*$|^((?:\+\d+)?\s*(?:\(\d+\)\s*(?:[/–-]\s*)?)?\d+(?:\s*(?:[\s/–-]\s*)?\d+)*)\s*$)/g,
      () => this.$t('contact.form.phone.errors.invalid')
    );

    /* ===== MESSAGE ===== */
    validators.message.set(/.*\S.*/, () =>
      this.$t('contact.form.message.errors.required')
    );
    validators.message.set(/.{10,}/, () =>
      this.$t('contact.form.message.errors.minLength')
    );

    this.validators = validators;
  },
};
</script>

<style lang="scss" scoped>
#contact {
  width: 100%;
  padding: 144px 0 96px 0;
  position: relative;
  overflow: hidden;

  @media all and ($desktop) {
    padding: 224px 0 144px 0;
  }

  .content {
    max-width: $max-view-width;
    height: auto;
    padding: 0 24px;
    margin: 0 auto;
    position: relative;

    .background-text {
      position: absolute;
      top: -192px;
      left: 35%;
      font-size: 18.75rem;
      font-weight: 700;
      line-height: 25.5625rem;
      color: rgba($foreground, 0.02);
      text-transform: uppercase;
      white-space: nowrap;
      user-select: none;

      @media all and ($mobile) {
        top: -132px;
        left: -14px;
        font-size: 11.25rem;
        line-height: 15.375rem;
      }
    }

    .foreground {
      position: relative;
      z-index: 1;

      h2.title {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        text-transform: uppercase;
        font-size: 1rem;
        font-weight: 300;
        letter-spacing: 0.15em;
        color: $foreground;
        margin-bottom: 32px;

        &::before {
          content: '';
          display: inline-block;
          width: 12px;
          height: 2px;
          background-color: $primary;
          margin: 0 8px 0 0;
        }
      }

      .headline {
        font-size: 1.5rem;
        font-weight: 700;
        color: $foreground;
        margin-bottom: 16px;

        @media all and ($desktop) {
          font-size: 2rem;
          margin-bottom: 24px;
        }
      }

      .inner {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        position: relative;

        @media all and ($mobile) {
          flex-wrap: wrap;
        }

        .dot-pattern {
          display: block;
          position: absolute;
          bottom: 0;
          left: 0;

          &::before {
            content: '';
            display: block;
            width: 260px;
            height: 148px;
            transform: translate(-20%, 10%);
            z-index: 0;

            @include circle-pattern(0.2);
          }

          @media all and ($mobile) {
            display: none;
          }
        }

        .text {
          width: 25%;

          @media all and ($mobile) {
            width: 100%;
            margin-bottom: 32px;
          }

          .subtitle {
            font-size: 1.25rem;
            font-weight: 700;
            color: $foreground;
            margin-bottom: 16px;

            @media all and ($desktop) {
              font-size: 1.5rem;
            }
          }

          .details {
            font-size: 1rem;
            line-height: 1.375rem;
            color: $text-color;
          }
        }

        form {
          width: 70%;
          display: grid;
          grid-template:
            [row1-start] 'name company' auto [row1-end]
            [row2-start] 'email phone' auto [row2-end]
            [row3-start] 'subject subject' auto [row3-end]
            [row4-start] 'message message' auto [row4-end]
            [row5-start] 'submit submit' auto [row5-end]
            / 1fr 0.67fr;
          gap: 0 24px;
          position: relative;

          @media all and ($mobile) {
            width: 100%;
            grid-template:
              [row1-start] 'name' auto [row1-end]
              [row2-start] 'company' auto [row2-end]
              [row3-start] 'email' auto [row3-end]
              [row4-start] 'phone' auto [row4-end]
              [row5-start] 'subject' auto [row5-end]
              [row6-start] 'message' auto [row6-end]
              [row7-start] 'submit' auto [row7-end]
              / 100%;
            gap: 0;
          }

          &:not(.input) {
            & > *:not(.feedback) {
              opacity: 0;
              pointer-events: none;
            }
          }

          .feedback {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            display: flex;
            justify-content: center;
            align-items: center;

            @media all and ($mobile) {
              width: 100%;
            }

            .loader {
              position: relative;
              margin: 0;
              width: 48px;

              &::before {
                content: '';
                display: block;
                padding-top: 100%;
              }

              .circular {
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                margin: auto;
                width: 100%;
                height: 100%;
                transform-origin: center center;
                animation: rotate 2000ms linear infinite;

                @keyframes rotate {
                  0% {
                    transform: rotate(0deg);
                  }
                  100% {
                    transform: rotate(360deg);
                  }
                }

                circle {
                  stroke: $text-color;
                  stroke-dasharray: 1, 200;
                  stroke-dashoffset: 0;
                  stroke-linecap: round;
                  animation: dash 1500ms ease-in-out infinite;

                  @keyframes dash {
                    0% {
                      stroke-dasharray: 1, 200;
                      stroke-dashoffset: 0;
                    }
                    50% {
                      stroke-dasharray: 89, 200;
                      stroke-dashoffset: -35px;
                    }
                    100% {
                      stroke-dasharray: 89, 200;
                      stroke-dashoffset: -124px;
                    }
                  }
                }
              }
            }

            & > *:not(.loader) {
              display: flex;
              flex-direction: column;
              justify-content: flex-start;
              align-items: center;
              opacity: 0;
              transform: scale(0.5);
              animation: fadeIn 300ms ease-out 1 forwards;

              @keyframes fadeIn {
                to {
                  opacity: 1;
                  transform: scale(1);
                }
              }

              .symbol {
                width: 96px;
                height: 96px;
                display: flex;
                justify-content: center;
                align-items: center;
                border-radius: 50%;
                background-color: $primary;

                svg path {
                  stroke-dasharray: 100;
                  stroke-dashoffset: -100;
                  animation: check 1s ease 1 forwards;

                  @keyframes check {
                    to {
                      stroke-dashoffset: 0;
                    }
                  }
                }
              }

              .title {
                font-size: 1.25rem;
                font-weight: 700;
                text-align: center;
                margin: 24px 0 12px 0;
                color: $foreground;
              }

              .details {
                font-size: 1rem;
                line-height: 1.125rem;
                font-weight: 400;
                text-align: center;
                margin: 0;
                color: $text-color;
              }
            }

            .error {
              .symbol {
                background-color: $error-color;

                svg {
                  line {
                    &:nth-child(1) {
                      stroke-dasharray: 50;
                      stroke-dashoffset: 50;
                      animation: dash1 500ms ease 1 forwards 250ms;

                      @keyframes dash1 {
                        to {
                          stroke-dashoffset: 0;
                        }
                      }
                    }

                    &:nth-child(2) {
                      opacity: 0;
                      animation: dash2 200ms ease 1 forwards 500ms;

                      @keyframes dash2 {
                        to {
                          opacity: 1;
                        }
                      }
                    }
                  }

                  path {
                    opacity: 1;
                    animation: blink 1.5s linear infinite forwards;

                    @keyframes blink {
                      50% {
                        opacity: 0.5;
                      }
                    }
                  }
                }
              }

              .details {
                white-space: pre-wrap;
                margin: 0 0 24px 0;

                a {
                  color: $foreground;
                  text-decoration: none;
                  outline: none;
                }
              }
            }
          }

          & > * {
            transition: opacity 150ms ease-in-out;

            &:nth-child(1) {
              grid-area: name;
            }

            &:nth-child(2) {
              grid-area: company;
            }

            &:nth-child(3) {
              grid-area: email;
            }

            &:nth-child(4) {
              grid-area: phone;
            }

            &:nth-child(5) {
              grid-area: subject;
            }

            &:nth-child(6) {
              grid-area: message;
            }

            &:nth-child(7) {
              grid-area: submit;
              justify-self: flex-end;
            }
          }
        }
      }

      .socialMedia {
        margin: 96px 0 0 0;

        .headline {
          font-size: 1.5rem;
          font-weight: 700;
          text-align: center;
          color: $foreground;
          margin-bottom: 32px;
        }

        .boxes {
          display: grid;
          grid-template-columns: repeat(auto-fit, minmax(256px, 1fr));
          grid-gap: 16px;
          width: 100%;

          @media all and ($mobile) {
            grid-template-columns: repeat(auto-fit, 50%);
            grid-gap: 0;
          }

          .box {
            flex-grow: 1;
            margin: 0;
            padding: 24px;
            border: 2px solid rgba($foreground, 0.08);
            border-radius: 12px;
            text-align: center;
            text-decoration: none;
            color: $text-color;
            outline: none;
            box-sizing: border-box;
            transition: background 120ms ease-in-out;

            &:hover {
              background-color: rgba($background-light, 0.1);
            }

            &:last-child {
              margin: 0;
            }

            @media all and ($mobile) {
              border: none;
              border-radius: 0;
            }

            i {
              font-size: 48px;
              width: 48px;
              height: 48px;
              color: $primary;
              text-align: center;
            }

            .title {
              margin: 12px 0 0 0;
              font-size: 1.25rem;
              line-height: 1.625rem;
              font-weight: 700;
              text-align: center;
              color: $foreground;
            }

            .name {
              margin: 0;
              font-size: 1rem;
              color: $text-color;
              text-align: center;

              @media all and ($mobile) {
                font-size: 0.75rem;
              }
            }
          }
        }
      }
    }
  }
}
</style>
