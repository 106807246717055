<i18n src="@/locales/pages/home.json"></i18n>

<template>
  <section id="portfolio">
    <div class="content">
      <p
        class="background-text rellax"
        data-rellax-speed="-1"
        data-rellax-percentage="0.5"
      >
        {{ $t('portfolio.title') }}
      </p>
      <div class="foreground">
        <h2 class="title">{{ $t('portfolio.title') }}</h2>
        <p class="headline">{{ $t('portfolio.headline') }}</p>

        <div class="slider">
          <button class="swiper-button-prev">
            <i class="icon-left" />
          </button>

          <div class="swiper-container">
            <div class="swiper-wrapper">
              <router-link
                class="swiper-slide"
                :to="'portfolio/' + project"
                v-for="project in projects"
                :key="project"
              >
                <div class="thumbnail">
                  <img
                    :src="
                      require(`@/assets/portfolio/${project}/thumbnail.jpg`)
                    "
                    :alt="$t(`portfolio.projects.${project}.title`)"
                  />
                </div>
                <p class="name">
                  {{ $t(`portfolio.projects.${project}.title`) }}
                </p>
                <p class="description">
                  {{ $t(`portfolio.projects.${project}.description`) }}
                </p>
                <BaseButton :to="'portfolio/' + project" class="more">{{
                  $t('portfolio.more')
                }}</BaseButton>
              </router-link>
            </div>
          </div>

          <button class="swiper-button-next">
            <i class="icon-right" />
          </button>

          <div
            class="dot-pattern rellax"
            data-rellax-speed="-.3"
            data-rellax-percentage=".2"
          ></div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import Swiper from 'swiper';

export default {
  name: 'PortfolioSection',
  data() {
    return {
      projects: [
        'designer-vintage-clothing',
        'fhg-online',
        'continuum-graphics-store',
        'fhg-news',
      ],
    };
  },
  mounted() {
    new Swiper('#portfolio .swiper-container', {
      spaceBetween: 24,
      slidesPerView: 3,
      grabCursor: true,
      navigation: {
        nextEl: '#portfolio .swiper-button-next',
        prevEl: '#portfolio .swiper-button-prev',
      },
      breakpoints: {
        0: {
          slidesPerView: 'auto',
        },
        300: {
          slidesPerView: 1,
        },
        450: {
          slidesPerView: 2,
          spaceBetween: 16,
        },
        830: {
          slidesPerView: 3,
        },
      },
    });
  },
};
</script>

<style lang="scss" scoped>
@import '~swiper/swiper';

#portfolio {
  width: 100%;
  padding: 96px 0 0 0;
  overflow: hidden;
  position: relative;

  @media all and ($desktop) {
    padding: 144px 0 0 0;
  }

  .content {
    max-width: $max-view-width;
    height: auto;
    padding: 0 24px;
    margin: 0 auto;
    position: relative;

    .background-text {
      position: absolute;
      top: -96px;
      left: 20%;
      font-size: 18.75rem;
      font-weight: 700;
      line-height: 25.5625rem;
      color: rgba($foreground, 0.02);
      text-transform: uppercase;
      white-space: nowrap;
      user-select: none;

      @media all and ($mobile) {
        top: -128px;
        left: -14px;
        font-size: 11.25rem;
        line-height: 15.375rem;
      }
    }

    .foreground {
      position: relative;
      z-index: 1;

      h2.title {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        text-transform: uppercase;
        font-size: 1rem;
        font-weight: 300;
        letter-spacing: 0.15em;
        color: $foreground;

        &::before {
          content: '';
          display: inline-block;
          width: 12px;
          height: 2px;
          background-color: $primary;
          margin: 0 8px 0 0;
        }
      }

      .headline {
        font-size: 1.5rem;
        font-weight: 700;
        text-align: left;
        color: $foreground;
        margin: 32px 0 48px 0;

        @media all and ($desktop) {
          font-size: 2rem;
          margin: 32px 0 64px 0;
        }
      }

      .slider {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        position: relative;

        .dot-pattern {
          display: block;
          position: absolute;
          top: 0;
          right: 0;

          &::before {
            content: '';
            display: block;
            width: 80px;
            height: 116px;
            transform: translate(22%, -40%);
            z-index: 0;

            @include circle-pattern(0.2);
          }

          @media all and ($desktop) {
            display: none;
          }
        }

        .swiper-container {
          width: calc(100% - 80px);
          border-radius: 16px 16px 0 0;

          .swiper-wrapper {
            .swiper-slide {
              text-decoration: none;
              outline: none;

              .thumbnail {
                display: flex;
                justify-content: center;
                align-items: center;
                border-radius: 16px;
                overflow: hidden;
                height: 192px;
                width: auto;
                background-color: #ebebeb;

                @media all and ($desktop) {
                  height: 256px;
                }

                img {
                  height: 100%;
                  width: auto;
                }
              }

              .name {
                margin: 8px 0 4px 0;
                font-size: 1rem;
                font-weight: 700;
                line-height: 1.1rem;
                color: $foreground;

                @media all and ($desktop) {
                  margin: 16px 0 8px 0;
                  font-size: 1.25rem;
                  line-height: 1.4rem;
                }
              }

              .description {
                margin: 0;
                font-size: 0.875rem;
                line-height: 1rem;
                font-weight: 400;
                color: $text-color;
              }

              .more {
                display: inline-flex;
                padding-left: 0;
                padding-right: 0;
                min-height: auto;
                margin-top: 8px;
                color: $text-color;
                font-weight: 700;
                background-color: transparent;
                transition: color 150ms ease;
              }

              &:hover {
                .more {
                  color: $primary;
                }
              }
            }
          }
        }

        button {
          border: none;
          background-color: transparent;
          outline: none;
          padding: 11px;
          margin: 0 8px;
          cursor: pointer;
          box-sizing: border-box;
          border-radius: 50%;
          z-index: 1;
          transition: background 100ms ease-in-out, opacity 200ms ease;

          &.swiper-button-disabled {
            opacity: 0.5;
            cursor: default;

            &:active {
              background-color: transparent;
            }
          }

          &:active {
            background-color: rgba($foreground, 0.1);
          }

          &.swiper-button-prev {
            left: -40px;
            margin: 0 8px 0 -8px;
          }

          &.swiper-button-next {
            right: -40px;
            margin: 0 -8px 0 8px;
          }

          i {
            width: 18px;
            height: 18px;
            font-size: 18px;
            color: $foreground;
          }
        }
      }
    }
  }
}
</style>
