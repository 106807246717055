<i18n src="@/locales/pages/home.json"></i18n>

<template>
  <section id="home">
    <div class="content">
      <div class="inner">
        <div class="text">
          <div class="title">
            <p class="before">{{ $t('home.title.before') }}</p>
            <h1 v-html="$t('home.title.headline')"></h1>
            <h2 class="subtitle">{{ $t('home.title.subtitle') }}</h2>
          </div>
          <div class="buttons">
            <BaseButton
              color="primary"
              class="portfolio"
              :to="{
                path: '/portfolio',
                params: this.$route.params,
                query: this.$route.query,
              }"
              replace
              >{{ $t('home.buttons.portfolio') }}
            </BaseButton>
            <BaseButton
              outline="white"
              class="contact"
              :to="{
                path: '/contact',
                params: this.$route.params,
                query: this.$route.query,
              }"
              replace
              >{{ $t('home.buttons.contact') }}
            </BaseButton>
          </div>
        </div>
        <div class="portrait">
          <picture>
            <source srcset="@/assets/home/portrait.webp" type="image/webp" />
            <source srcset="@/assets/home/portrait.png" type="image/png" />
            <img
              src="@/assets/home/portrait.png"
              alt="Portrait"
              class="rellax"
              data-rellax-speed="-1"
            />
          </picture>
        </div>
      </div>
      <router-link
        tag="div"
        :to="{
          path: '/about',
          params: this.$route.params,
          query: this.$route.query,
        }"
        replace
        class="scroll"
      >
        <p>{{ $t('home.scrollDown') }}</p>
        <div class="arrow"></div>
      </router-link>
    </div>
  </section>
</template>

<script>
import fitty from 'fitty';

export default {
  name: 'HomeSection',
  mounted() {
    fitty('#home .title h1', {
      minSize: 32,
    });
  },
};
</script>

<style lang="scss" scoped>
#home {
  width: 100%;
  height: calc(100vh - 54px);
  overflow: hidden;

  @media all and ($desktop) {
    height: calc(100vh - 64px);
  }

  .content {
    display: flex;
    position: relative;
    max-width: $max-view-width;
    height: 100%;
    padding: 0 24px;
    margin: 0 auto;

    @media all and ($mobile) {
      align-items: center;
    }

    .inner {
      display: flex;
      justify-content: stretch;
      align-items: center;
      height: 90%;
      width: 100%;

      @media all and ($mobile) {
        position: relative;
        height: auto;
        padding: 30% 0 0 0;
        align-items: flex-end;
      }

      .text {
        width: 60%;
        z-index: 1;
        position: relative;

        @media all and ($mobile) {
          width: 100%;
          max-width: 100%;
          position: initial;
        }

        &::before {
          content: '';
          display: block;
          width: 120px;
          height: 204px;
          position: absolute;
          top: 0;
          left: 0;
          transform: translate(-60%, -92%);

          @include circle-pattern(0.2);

          @media all and ($mobile) {
            width: 44px;
            height: 152px;
            // top: auto;
            top: 0;
            left: -24px;
            transform: translate(0, calc(-13vh));
          }
        }

        .title {
          .before,
          .subtitle {
            font-size: 1.125rem;
            font-weight: 400;
            color: $text-color;

            @media all and ($desktop) {
              font-size: 1.5rem;
            }
          }

          h1 {
            font-size: 2rem;
            font-weight: 600;
            line-height: 2rem;
            color: $foreground;
            margin: 8px 0;

            @media all and ($desktop) {
              font-size: 3.375rem;
              line-height: 4.625rem;
              margin: 2px 0;
            }

            @media all and ($desktop) and (max-width: $max-view-width) {
              line-height: normal;
            }
          }
        }

        .buttons {
          margin: 48px 0 0 0;

          @media all and ($desktop) {
            margin: 32px 0 0 0;
          }

          & > * {
            display: inline-flex;
            margin: 0 24px 16px 0;
          }
        }
      }

      .portrait {
        display: flex;
        width: 40%;
        position: relative;
        overflow: visible;

        @media all and ($mobile) {
          position: absolute;
          top: 12%;
          right: calc(-2% - 24px);
          width: 75%;
          transform: translateY(-50%);
        }

        img {
          width: 100%;
          height: auto;
        }

        &::after {
          content: '';
          display: block;
          width: 260px;
          height: 148px;
          position: absolute;
          right: 0;
          bottom: 0;
          transform: translate(30%, 50%);

          @include circle-pattern(0.5);

          @media all and ($mobile) {
            display: none;
          }
        }
      }
    }

    .scroll {
      $gray: rgba($foreground, 0.5);

      position: absolute;
      bottom: 16px;
      left: 24px;
      transform: translateX(-50%);
      user-select: none;

      @media all and ($mobile) {
        left: auto;
        right: 24px;
        transform: translateX(50%);
      }

      p {
        font-size: 1.125rem;
        font-weight: 300;
        letter-spacing: 0.1em;
        color: $gray;
        writing-mode: tb-rl;
        transform: rotate(-180deg);
        cursor: pointer;

        @media all and ($desktop) and (max-height: 700px) {
          display: none;
        }

        @media all and ($mobile) {
          font-size: 1rem;
        }
      }

      .arrow {
        position: relative;
        height: calc(30vh - 140px);
        width: 1px;
        background-color: $gray;
        margin: 16px auto 5px auto;

        @media all and ($desktop) and (max-height: 700px) {
          height: calc(50vh - 190px);
        }

        @media all and ($mobile) {
          height: calc(33vh - 140px);
        }

        &::after {
          content: '';
          position: absolute;
          top: 100%;
          left: 50%;
          display: block;
          margin: 0 auto;
          width: 0;
          height: 0;
          border-left: 4px solid transparent;
          border-right: 4px solid transparent;
          border-top: 5px solid $gray;
          transform: translateX(-50%);
        }
      }
    }
  }
}
</style>
